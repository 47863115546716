import React, {Component} from 'react';
import random from 'random-key';

import Header from '../Header';
import Footer from '../Footer';

import {database} from '../database';
import {history} from '../utils';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: ''
    };
  }

  handleSubmit = () => {
    const {url} = this.state;
    const key = random.generate(6);

    database.ref(key).set(url);
    history.push(`/r/${key}`);
  };

  render() {
    return (
      <div className="site-wrapper">
        <Header />
        <div className="main-wrapper">
          <main>
            <h1>Shorten your links.</h1>

            <p className="lead">
              Paste a full URL below and have it shortened!
            </p>

            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-lg"
                name="url"
                placeholder="Long URL Here"
                required
                onChange={e => this.setState({url: e.target.value})}
              />
              <span className="input-group-btn">
                <button
                  className="btn btn-primary btn-lg"
                  onClick={this.handleSubmit}
                >
                  Go!
                </button>
              </span>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
