import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';

import './styles/normalize.css';
import './styles/index.css';

import {history} from './utils';
import routes from './routes';

import './database';

const application = <Router history={history}>{routes}</Router>;

ReactDOM.render(application, document.getElementById('root'));
