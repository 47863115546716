import React from 'react';

const Header = () => (
  <div className="header-wrapper">
    <header style={{display: 'flex', justifyContent: 'center'}}>
      <h3 className="brand">
        <a href="/">URL Shortener</a>
      </h3>
      <nav className="nav">
        {/* <a href="https://github.com/MichaelKovich">
            <i className="my-github"></i>
            GitHub
          </a> */}
      </nav>
    </header>
  </div>
);

export default Header;
