import * as firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyCIz07GJ_l0P3wIuvErXHRXpl61kI5MAlU',
  authDomain: 'tkimail-b2b6e.firebaseapp.com',
  databaseURL: 'https://tkimail-b2b6e.firebaseio.com',
  projectId: 'tkimail-b2b6e',
  storageBucket: '',
  messagingSenderId: '320409587662',
  appId: '1:320409587662:web:d6e8adab5399eee485259f'
};

export default firebase.initializeApp(firebaseConfig);
export const database = firebase.database();
