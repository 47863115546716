import React from 'react';

import Header from '../Header';
import Footer from '../Footer';

import {rootDomain} from '../utils';

const Result = props => {
  let id = '';

  if (props && props.match && props.match.params && props.match.params.id) {
    id = props.match.params.id;
  }

  const handleCopy = () => {
    const textToCopy = document.querySelector('.output');

    textToCopy.select();
    document.execCommand('copy');
  };

  return (
    <div className="site-wrapper">
      <Header />
      <div className="main-wrapper">
        <main>
          <h1>Success!</h1>

          <p className="lead">Here's your shortened link!</p>

          <form>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-lg output"
                value={`${rootDomain}${id}`}
                readOnly
              />
              <span className="input-group-btn">
                <button
                  className="btn btn-primary btn-lg"
                  type="button"
                  onClick={handleCopy}
                >
                  <i className="fa fa-clipboard"></i>
                </button>
              </span>
            </div>
          </form>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Result;
