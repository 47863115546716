import React, {Component} from 'react';

import {database} from '../database';

class Redirector extends Component {
  componentDidMount() {
    let id = '';

    if (
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      id = this.props.match.params.id;
    }

    database
      .ref(id)
      .once('value')
      .then(function(snapshot) {
        const destination = snapshot.val();

        if (!destination) return;
        window.location.replace(destination);
      });
  }

  render() {
    return <div>Please wait...</div>;
  }
}

export default Redirector;
