/** *******************************************************************
 * Absolute Imports
 ******************************************************************* */
import React from 'react';
import {Switch, Route} from 'react-router-dom';

/** *******************************************************************
 * Relative Imports
 ******************************************************************* */
import Dashboard from './Dashboard';
import Result from './Result';
import Redirector from './Redirector';

/** *******************************************************************
 * Redux and Exports
 ******************************************************************* */
export default (
  <Switch>
    <Route component={Dashboard} exact path="/" />
    <Route component={Result} path="/r/:id" />
    <Route component={Redirector} path="/:id" />
    {/* <Route path="/404" component={NotFound} />
    <Redirect from="*" to="/404" /> */}
  </Switch>
);
